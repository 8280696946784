<template>
<div class="Registry">
     <ContainerCard  :width="61">
        <div class="info">
        <div class="infomain">
          <div class="info-title">
              <span class="label">网站服务协议</span>
         </div>          
          <div class="standard">
            <p class="artContent">一、总则</p>
            <ul>
                <li >本服务协议双方为泉州黎明大学与福建国科科技有限公司，本服务协议具有合同效力。</li>
                <li >1) 您在使用一点知识提供的各项服务之前，应仔细阅读本服务协议。如果您不同意本服务协议以及本公司随时对其可能做出的修改，您可以主动取消一点知识为您提供的服务；一经注册为用户，即表示您已了解并完全同意本服务协议各项内容及一点知识对服务协议随时所做的任何修改。</li>
                <li >2) 本公司有权在必要时单方修改或变更本服务协议之内容，并将通过本公司网站公布最新的服务协议，无需另行单独通知您。</li>
                <li >3) 本网站的注册用户必须是具有法律规定的完全民事权利能力和民事行为能力，能够独立承担民事责任的企事业代表或其他机构组织；本协议内容不受您所属国家或地区的排斥。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">二、服务内容</p>
            <ul>
                <li >一点知识助力企业快速创建、运营自己的网络学堂，通过获取、创建、管理、学习、分享信息和知识资产，以服务于企业内部的员工、客户和合作伙伴，实现将企业的智力资产转化为企业的竞争力，从而提升企业的整体能力。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">三、使用规则</p>
            <ul>
                <li >用户在使用一点知识所提供的各项服务时，必须遵守中华人民共和国相关法律法规的规定，用户应同意将不会利用本服务进行任何违法或不正当的活动，包括但不限于下列行为：</li>
                <li >1) 上传、传播或以其它方式传送含有反动，色情，及种族歧视等内容的信息；</li>
                <li >2) 从事非法交易活动，如洗钱，提供赌博咨询，提供色情服务等；</li>
                <li >3) 非法使用他人银行账户或无效的银行账号进行交易；</li>
                <li >4) 违反与网络服务有关的网络协议、规定和程序；</li>
                <li >5) 不得利用一点知识网络服务系统进行任何不利于一点知识的行为，或为任何非法目的而使用本网络服务系统；</li>
                <li >6) 不得利用一点知识网络服务系统进行任何可能对互联网正常运转造成不利影响的行为；</li>
                <li >7) 不得侵犯他人专利权、著作权、名誉权或其他任何合法权益及知识产权，否则将承担所有相关法律责任；</li>
                <li >8) 如发现任何非法使用用户帐号或帐号出现安全漏洞的情况，应立即通告一点知识。本公司在发现异常操作或有违法行为时，有权不经通知先行暂停或终止该用户对本网络服务系统部分或全部功能的使用。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">四、协议双方权利和义务</p>
            <div style="margin:8px auto">
              <div style="margin-bottom:10px">（一） 一点知识的权利和义务</div>
                    <ul>
                        <li >1) 一点知识负责网络交易平台的建设、运行和维护，保证为客户提供安全有效的网络服务和操作环境。</li>
                        <li >2) 一点知识负责解答用户在使用网络交易平台进行操作的过程中遇到的各种疑问。</li>
                        <li >3) 对于用户在一点知识平台上的不当行为或其它任何一点知识认为应当终止服务的情况，一点知识有权根据不同情况选择保留或删除相关信息或停止对该用户提供服务，并追究相关法律责任。</li>
                        <li >4) 一点知识会在用户的计算机上设定或取用一点知识 cookies。一点知识在用户登录时获取数据，使用cookies可为用户用户提供个性化服务。 如果用户拒绝所有cookies，将不能使用需要登录的一点知识服务内容。</li>
                        <li >5) APP将收集用户的学习数据，并根据学习情况计算学员的学分，并将这些数据上传至服务端，APP会根据学分对学员成绩进行排名。</li>  
                    </ul>
            </div>
            <div>
               <div style="margin-bottom:10px">（二） 用户权利和义务</div>
                    <ul>
                        <li >1) 用户须严格遵守一点知识的管理和运行规定,并享有一点知识为用户提供的服务和优惠条件。</li>
                        <li >2) 用户必须保证其向一点知识提供有关资料的真实性，如基本信息说明，邮箱，联系电话等。用户须独立承担因其资料不准确、不真实而引发的一切责任。</li>
                        <li >3) 用户不得将本网络服务系统用于非本公司许可的其他用途。</li>
                        <li >4) 用户在网上发布的所有信息和从事的交易活动必须严格遵守现行法律法规及有关国家规定，并承担与此相应的责任。</li>
                    </ul>
            </div>
          </div>


          <div class="standard">
            <p class="artContent">五、禁止与限制发布知识产品</p>
            <ul>
                <li >由于发布以下知识产品或服务所引起的法律责任由相关用户完全承担，与一点知识无关。一旦一点知识发现有任何违反本规则的服务信息，一点知识有权立即予以删除，并保留给予相关用户警告、冻结直至终止其帐户的权力。请各位用户自觉遵守。

禁止发布的知识产品和服务：</li>
                <li >1). 含有反动、淫秽、种族或者宗教歧视以及其它法律禁止内容的知识产品和服务；</li>
                <li >2). 涉及恐怖活动、邪教组织等反社会的产品和服务信息；</li>
                <li >3). 涉及国家秘密的信息等；</li>
                <li >4). 伪造的服务信息；</li>
                <li >5). 黄色淫秽产品、色情服务；</li>
                <li >6). 侵犯他人知识产权或违反国家法律法规条例的服务信息；</li>
                <li >7). 其它可能危害他人安全和利益的服务信息。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">六、知识产权的保护</p>
            <ul>
                <li >1) 一点知识网站上的所有内容，包括但不限于档案、图片、资料、资讯、网站架构、网页设计，均由本公司或本公司相关企业依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。</li>
                <li >2) 非经本公司或其他权利人书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表本网站程序或内容。</li>
                <li >3) 尊重知识产权是您应尽的义务，如有违反，您须承担相应的损害赔偿责任。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">七、注册信息及隐私保护</p>
            <ul>
                <li >1) 您注册为一点知识用户时应向本公司提供真实的电话号码及（或）相关附加信息。您同意本公司通过您所提供的联系方式有针对性地向您提供新的服务和机会。 </li>
                <li >2) 在取得该账户后及时更新您的最新及完整的资料，在提交正式使用申请时，客服会验证您提交的信息是否正确，以决定是否批准您的正式申请。若因您未及时更新资料，导致本服务无法提供或提供时发生任何错误，您将承担因此产生的一切后果，本公司不承担任何责任。</li>
                <li >3) 您必须对您的账户负责，该账户不可转让、不可赠与、不可继承。在您决定不再使用该账户时，您应将该账户下所对应的可用款项全部提现或者向本公司发出其它支付指令，并向本公司申请注销该账户。</li>
                <li >4) 本公司所提供的支付服务将需要您提供您的银行账户信息，本公司将严格履行相关保密约定。</li>
                <li >5) 您了解并同意本公司使用cookie来使一点知识网站对用户更友好，它可以帮您省去为使用我们的服务而重复输入注册信息和跟踪您的浏览器的状态。。</li>
                <li >6) 为保障网络服务的安全性及服务质量的改进，本公司将记录并保存您登录和交易活动的相关信息，并承诺不将此类信息提供给任何第三方（除双方另有约定或法律法规另有规定外）。</li>
                <li >7) 一点知识网站含有到其他网站的链接，但本公司对其他网站的隐私保护措施不负任何责任。本公司可能会与第三方合作向用户提供相关的本服务，在此情况下，如该第三方同意承担与本公司同等的保护用户隐私的责任，则本公司可将用户的个人信息等提供给该第三方。</li>
                <li >8) 本公司仅按现有技术向用户提供相应的安全措施来使本公司掌握的信息不丢失，不被滥用或更改。在不透露单个用户隐私资料的前提下，本公司有权对整个用户数据库进行分析并对用户数据库进行商业上的利用（包括但不限于公布、分析或以其它方式使用用户访问量、访问时段、用户偏好等用户数据信息）。但本公司不保证这些信息的绝对安全。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">八、服务变更、中断或终止</p>
            <ul>
                <li >1) 鉴于网络服务的特殊性，用户同意一点知识有权随时变更、中断或终止部分或全部的网络服务。本公司有权不经向您特别通知而对软件进行更新，或者对软件的部分功能效果进行改变或限制。本软件新版本发布后，旧版本的软件可能无法使用。本公司不保证旧版本软件继续可用及相应的客户服务，请您随时核对并下载最新版本。本公司无需将变更，中断或终止内容通知用户，也无需对任何用户或任何第三方承担任何责任。</li>
                <li >2) 一点知识有权在必要时修改服务条款，并将在重要页面上提示修改内容。如果不同意所改动的内容，用户可以主动取消获得的网络服务。如果用户继续享用网络服务，则视为接受服务条款的变动。</li>
                <li >3) 如用户向一点知识提出注销注册用户身份时，经审核同意，由一点知识注销该注册用户，并解除与之服务协议关系。</li>
                <li >4) 如用户提供的个人资料不实或违反本协议中规定的使用规则，一点知识有权随时中断或终止向用户提供本协议项下的网络服务，并无需对用户或任何第三方承担任何责任。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">九、免责声明</p>
            <ul>
                <li >1) 用户同意自行承担对使用一点知识网络服务所产生的风险，一点知识对用户不承担任何责任。</li>
                <li >2) 对所提供的网络服务，一点知识不担保一定能满足用户的要求，对网络服务的及时性、安全性、准确性不作担保。</li>
                <li >3) 对于因不可抗力或一点知识不能控制的原因造成的网络服务中断或其它缺陷，一点知识不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</li>
                <li >4) 一点知识保留在不事先通知用户的情况下随时中断或终止部分或全部网络服务的权利，对于所有服务的中断或终止而造成的任何损失，无需对用户或任何第三方承担任何责任。</li>
                <li >5) 如因系统维护或升级的需要，一点知识有权暂停网络服务。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">十、违约责任</p>
            <ul>
                <li >1) 如因用户违反有关法律、法规或本协议项下的任何条款而给一点知识或其他用户造成损失，用户同意承担由此造成的损害赔偿责任。</li>
                <li >2) 如因一点知识违反有关法律、法规或本协议项下的任何条款而给用户造成损失，一点知识同意承担由此造成的损害赔偿责任。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">十一、法律适用与争议解决</p>
            <ul>
                <li >1) 协议双方应以友好协商方式解决协议履行过程中产生的争议与纠纷。</li>
                <li >2) 协商无效时，应提交当地法院通过诉讼解决。</li>
                <li >3) 本协议之效力、解释、执行、争议解决等均适用中华人民共和国和用户所在国家相关法律法规，没有相关法律规定的内容，参照通用国际商业惯例和（或）行业惯例。</li>
            </ul>
          </div>

          <div class="standard">
            <p class="artContent">十二、本协议自2017年10月15日起实行，解释权归一点知识泉州黎明大学所有。</p>
          </div>


        </div>
      </div>
     </ContainerCard>
</div>
  
</template>

<script>
import HomeNav from "../Layout/components/HomeNav.vue";
import Footer from "../Layout/components/Footer.vue";
import ContainerCard from "../../components/newContainerCard.vue";
export default {
    name:'Registry',
    data() {
        return{

        }
    },
    components:{ HomeNav , Footer ,ContainerCard }

}
</script>

<style scoped lang="scss">
// .Registry {
//     margin:  0 auto;
//     display: flex;
//     flex-direction: column;
//     flex-wrap: wrap;
// }

// .registryText {
//     // display: flex;
//     // flex-direction:column;
//     // flex-wrap: wrap;
//     width: 756px;
//     background: red;
//     margin: 0 auto;
//     padding: 20px 50px 36px 50px;
//     font-size: 16px;
//     font-family: SourceHanSansCN-Regular, SourceHanSansCN;
//     font-weight: 400;
//     color: #404040;
//     line-height: 36px;
//     text-align: justify;
// }

.Registry {
    .info {
  margin-bottom: 20px;
  margin-top: 24px;
  .infomain {
    margin: 20px 0;
    ul {
        text-align: justify;
    }
    li {
        line-height: 24px;
    }
    .info-title {
        margin-bottom: 24px;
        text-align: center;
        img {
          width: 8px;
          height: 10px;
          margin-right: 12px;
        }
        .label {
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 600;
          color: #101010;
          line-height: 24px;
        }
      }
    .standard {
    .artContent {
      margin-top: 20px;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #404040;
      line-height: 36px;
      text-align: justify;
    }

    }

  }
}

}

</style>